<template>
  <div>
  </div>
</template>

<script>


export default {
  name: 'Dashboard',
  
  data () {
    return {
      
    }
  },
  
}
</script>
